dmx.Attribute('animate-move', 'mounted', function (node, attr) {
  if (this.type !== 'repeat-item') {
    console.warn('Attribute animate-move is only allowed on repeat items');
    return;
  }

  if (!this.parent.props.key) {
    console.warn('Attribute animate-move can only be used on keyed repeaters');
    return;
  }

  const { delay, duration, random } = attr.modifiers;
  const easing = ['linear', 'ease-in', 'ease-out', 'ease-in-out'].find(easing => !!attr.modifiers[easing]);
  const repeater = this.parent;
  const updateHandler = event => {
    node.pos = node.getBoundingClientRect();
  };
  const updatedHandler = event => {
    if (node.pos) {
      node.style.removeProperty('transform');
      node.style.removeProperty('transition');

      requestAnimationFrame(() => {
        const { left, top } = node.getBoundingClientRect();

        node.style.setProperty('transform', `translate(${node.pos.left - left}px, ${node.pos.top - top}px)`);

        requestAnimationFrame(() => {
          node.style.setProperty('transition', `transform ${duration || 800}ms ${easing || 'ease'} ${delay ? ((random ? Math.random() * 10 : this.data.$index) * delay) : 0}ms`);
          node.style.removeProperty('transform');
        });
      });
    }
  };

  repeater.addEventListener('update', updateHandler);
  repeater.addEventListener('updated', updatedHandler);

  this.addEventListener('destroy', event => {
    repeater.removeEventListener('update', updateHandler);
    repeater.removeEventListener('updated', updatedHandler);
  })
});